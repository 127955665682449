<template>
  <div v-if="Object.keys(OrderInfo).length > 0" class="OrderDetail main-content bg-white p-10">

    <div class="p-20 pt-10">
      <div v-if="!open">
        <div style=" text-align: center; margin-bottom: 30px;font-size: 22px;color: #333;">
          <i class="el-icon-success" style="font-size: 33px;color: #6fc239;margin-right: 8px;vertical-align: bottom;"></i>
          {{ Number(OrderInfo.status) === 1 && OrderInfo.refundStatus == null ? '该订单已支付成功' : '提交' }}
          <span v-show="!(Number(OrderInfo.status) === 1 && OrderInfo.refundStatus == null)" style="color: red; display: inline-block; margin: 0px 4px;">1</span>
          {{ Number(OrderInfo.status) === 1 && OrderInfo.refundStatus == null ? '' : '笔订单成功' }}
        </div>
        <div v-show="!(Number(OrderInfo.status) === 1 && OrderInfo.refundStatus == null)" style="font-size: 16px; text-align: center; margin-bottom: 16px;margin-bottom: 50px;">
          为保证价格和库存不影响您的订单，请您尽快完成支付，否则订单会自动取消!
        </div>
      </div>
      <!-- <div style="font-size: 14px; text-align: center;margin-bottom: 50px;">
        支付剩余时间：<span style="color: red;">{{ getTime() || '超时未支付，订单已取消' }}</span>
      </div> -->
      <el-table
        ref="multipleTable"
        :border="false"
        :data="tableData"
        style="width: 100%"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange">
        <el-table-column width="55"> </el-table-column>
        <el-table-column label="商品" width="420">
          <template slot-scope="scope">
            <div class="dis">
              <img :src="scope.row.picUrl" style="width:80px;height:80px;"></img>
              <div >
                <span style="font-size: 14px;">{{ scope.row.name }}</span>
                <div class="p-10">
                  <span>贸易类型：</span>
                  <span style="font-size: 14px;">{{ tp[OrderInfo.product.tradeType] }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="规格" prop="name">
          <template slot-scope="scope">
            <span v-for="(it,index) in scope.row.specInfo" :key="index" style="font-size: 14px;">{{ it }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="规格价" prop="salesPrice">
          <template slot-scope="scope">
            <span style="font-size: 14px;">￥{{ scope.row.salesPrice.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="数量" prop="quantity" > </el-table-column>
        <el-table-column align="center" label="金额" prop="salesPrice" >
          <template slot-scope="scope">
            <span style="font-size: 14px;">￥{{ (scope.row.quantity*scope.row.salesPrice).toFixed(2) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="dis pt-20" style="border-top:1px solid #ddd;margin-bottom: 30px;">
        <div class="disFixe">
          <div v-show="!(Number(OrderInfo.status) === 1 && OrderInfo.refundStatus == null)">
            <div v-for="(item,index) in payList" :key="item.name" :style="payType != index ? 'border:1px solid #ddd;':'border:2px solid #df1f23;'" class="active" @click="payTypeClick(index)">
              <img :src="item.img" alt="" style="width:40px;height: 40px;"></img>
              <span style="font-size:14px;margin-left:10px;" >{{ item.name }}</span>
              <span v-if="item.name === '余额支付'" class="ml-10" style="font-size: 12px">
                {{ ` (${overallBalance}元) ` }}
              </span>
            </div>
          </div>
        </div>
        <div style="">
          <div class="disFixe dis">
            <div class="w">订单编号：</div>
            <div>{{ OrderInfo.id }}</div>
          </div>
          <div class="disFixe dis" >
            <div class="w">创建时间：</div>
            <div>{{ OrderInfo.createTime }}</div>
          </div>
          <div class="disFixe dis" >
            <div class="w">运费总计：</div>
            <div>￥{{ OrderInfo.freightPrice.toFixed(2) }}</div>
          </div>
          <div class="disFixe dis" >
            <div class="w">寄送至：</div>
            <div >{{ OrderInfo.orderLogistics.address }}</div>
          </div>
          <div class="disFixe dis" >
            <div class="w">应付总计：</div>
            <div >￥{{ OrderInfo.paymentPrice.toFixed(2) }}</div>
          </div>
        </div>
      </div>


      <div style="text-align: end;display: flex;flex-flow: row;justify-content: right;align-items: center;">
        <div style="text-align: right; margin-right: 50px;" > 实付金额：
          <span style="color:#f60;;font-size: 20px;font-weight: 600;">
            {{ '￥' + OrderInfo.paymentPrice.toFixed(2) }}
          </span> </div>
        <a-button
          v-if="Number(OrderInfo.status) === 0"
          class="mr-10"
          @click="ModalOrderCancel = true">取消订单
        </a-button>
        <a-button
          v-if="Number(OrderInfo.status) === 0"
          type="primary"
          @click="selectPaymentOk">立即支付
        </a-button>
        <!--        Number(OrderInfo.status) !== 0 && (OrderInfo.refundStatus != 211 || OrderInfo.refundStatus != 221)-->
        <a-button
          v-if="Number(OrderInfo.status) === 1 && OrderInfo.refundStatus == null "
          class="mr-10"
          @click="ModalRefund = true">申请售后
        </a-button>
      </div>

      <RefundModal v-if="OrderInfo.id" :id="OrderInfo.id" v-model="ModalRefund" :selectOrderObj="OrderInfo" @ok="RefundOrderFinish"></RefundModal>
      <OrderCancel
        v-if="OrderInfo.id"
        :id="OrderInfo.id"
        v-model="ModalOrderCancel"
        @ok="CancelOrderFinish"></OrderCancel>
      <!-- 支付方式 -->
      <!-- <PaymentMethodModal v-model="ModalPay" @ok="selectPaymentOk"></PaymentMethodModal> -->
      <WechatPayModal
        v-if="OrderInfo.id && OrderInfo.orderNo && OrderInfo.status == '0'"
        v-model="ModalWechatPay"
        :codeUrl="codeUrl"
        :identifying="1"
        :orderId="OrderInfo.id"
        :orderSn="OrderInfo.orderNo"
        :wxpay="OrderInfo.paymentPrice"
        @ok="$router.go(0)">
      </WechatPayModal>
      <el-dialog
        :before-close="handleClose"
        :show-close="false"
        :visible.sync=" openModel"
        center
        title="提示"
        width="30%">
        <div style="text-align: center;">
          <span>您是否已成功支付？</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="openModelClick">取 消</el-button>
          <el-button type="primary" @click="openModelClick">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :before-close="handleClosePay"
        :close-on-click-modal="false"
        :visible.sync="yupayVisible"
        center
        title="余额支付"
        width="600px">
        <div style="text-align: center;">
          <div style=" margin-bottom: 20px;" >
            实付金额：<span style="color:#f60;;font-size: 20px;font-weight: 600;">{{ '￥' + OrderInfo.paymentPrice.toFixed(2) }}</span>
          </div>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="手机号"
              style="padding-bottom: 15px">
              <a-input
                v-model="userInfoPhone"
                :disabled="true"
                style="width: 100%"/>
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="验证码"
              style="padding-bottom: 15px">
              <a-row :gutter="20">
                <a-col :span="18">
                  <a-input
                    v-decorator="['code', { rules: [{ required: true, message: '请填写验证码' }]}]"
                    placeholder="请输入验证码"
                    style="width: 100%"/>
                </a-col>
                <a-col :span="2">
                  <el-button :disabled="this.code.status === 1" size="small" style="background-color: #205fb9;" type="primary" @click="getCode">
                    {{ codeText }}
                  </el-button>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-button :loading="loading" html-type="submit" size="small" style="left: 100px;width: 120px;;" type="primary">确认支付</a-button>
              <a-button v-if="Money === 0" size="small" style="left: 120px;width: 120px;;" type="info" @click="$router.push({path:'/user/fina/recharge'})">去充值</a-button>
            </a-form-item>
          </a-form >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { OrderStatusMap, OrderRefundStatusMap } from '@/Map/OrderStatusMap'
import { getUserOrderObj, unifiedOrder ,yuePay} from '@/api/Order'
import PaymentMethodModal from '@/components/Modal/PaymentMethodModal'
import WechatPayModal from '@/components/Modal/WechatPayModal'
import OrderCancel from '@/views/User/Order/OrderCancel'
import RefundModal from '@/components/Modal/RefundModal'
import { getMoney } from '@/api/finance/stream'
import { getUserInfoRz } from '@/api/Login'
import { Modal } from 'ant-design-vue'

const statusMap = OrderStatusMap
const aftermarketMap = OrderRefundStatusMap
/**
 * @author  XuHongli
 * @date  2022/9/8 12:18
 * @version 1.0
 * @description 订单详情
 */
export default {
  name: 'OrderDetail',
  components: { RefundModal, OrderCancel, WechatPayModal, PaymentMethodModal },
  data() {
    return {
      loading: false, // 确认支付加载
      codeUrl: '',  // 微信的二维码
      noModal: false,
      overallBalance: Number,
      userInfoPhone: '',
      amount: null,
      OrderId: '',
      OrderInfo: {},
      ModalRefund: false,
      ModalOrderCancel: false,
      ModalWechatPay: false,
      yupayVisible:false,
      ModalPay: false,
      tableData: [],
      multipleSelection: [],
      value:'',
      isc:0,
      payType: 0,
      time:'',
      open:'',
      openModel:false,
      payList:[
        {name:'微信支付',img:require('@/assets/logos/wechat.png')},
        {name:'支付宝支付',img:require('@/assets/logos/alipay.png')},
        {name:'余额支付',img:require('@/assets/logos/yu.png')},
      ],
      code: {
        status: 0, // 0 获取验证码 1 已发送{count}秒 2 重新发送
        count: '',
        timer: null,
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 13}
      },
      labelCol: {
        xs: {span: 24},
        sm: {span: 7}
      },
      form:this.$form.createForm(this),
      uuid:'',
      Money:''
    }
  },
  filters: {
    aftermarketFilter(type) {
      return aftermarketMap[type].text
    },
    aftermarketTypeFilter(type) {
      return aftermarketMap[type].refundStatus
    },
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  created() {
    this.OrderId = this.$route.params.id
    getUserOrderObj(this.OrderId).then((res) => {
      this.OrderInfo = res.data
      this.amount = res.data.supplyOrderItem.length ? res.data.supplyOrderItem[0].quantity : 1
      this.tableData = res.data.supplyOrderItem
      console.log('订单信息', this.tableData)
      if (this.$route.query.open === 'true' && Number(this.OrderInfo.status) === 0) {
        this.ModalPay = true
      }
    })
    this.getTime()
    this.open = this.$route.query.open
  },
  mounted() {
    getMoney().then((res) => {
      this.overallBalance = res.data.toFixed(2)
    })
  },
  computed:{
    tp(){
      return{
        1:'保税直供',
        2:'完税进口',
        3:'国内贸易',
        4:'香港直邮',
        5:'海外直邮'
      }
    },
    codeText() {
      if (this.code.status === 1) {
        return `验证码已发送 ${this.code.count}秒`
      } else if (this.code.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    }
  },
  methods: {
    // 获取余额支付验证码
    getCode(){
      const times = 60 // 倒计时时间
      if (!this.timer) {
        yuePay().then(res => {
          console.log('获取余额支付验证码',res)
          this.$message.success('发送验证码成功')

          this.uuid = res.data
        }).catch((err) => {
          this.code.timer = null,
          clearInterval(this.code.timer)

        })

        this.code.count = times
        this.code.status = 1
        this.code.timer = setInterval(() => {
          if (this.code.count > 0 && this.code.count <= times) {
            this.code.count--
          } else {
            this.code.status = 2
            clearInterval(this.code.timer)
            this.code.timer = null
          }
        }, 1000)
      }


    },
    handleSubmit(e) {
      this.loading = true
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values,values.code,this.uuid)
          const yueData = {
            orderId: this.OrderInfo.id,
            orderSn: this.OrderInfo.orderNo,
            payType: 'yue',
            code:values.code,
            uuid:this.uuid
          }
          // getMoney().then(res => {
          //   this.Money = res.data
          // })
          // if(this.Money <= 0){
          //   return this.$message.error('当前余额不足，请先充值！')
          // }
            unifiedOrder(yueData).then((res) => {
              if(res.ok) {
                this.loading = false
                this.yupayVisible = true
                this.$router.push({path: '/user/order'})
              }
            }).catch(err => {
              this.loading = false
            })
        }
      })
    },
    payTypeClick(index){
      console.log('payTypeClick',index)
      this.payType = index
    },
    handleClose(done) {
      this.openModel = true
    },
    handleClosePay(){
      console.log('sssss')
      this.yupayVisible = false
    },
    openModelClick(){
      this.$router.push({path:'/user/order'})
    },
    getTime(){
      let date = new Date()
      var now = date.getTime()
      //设置截止时间
      var str='2022/12/2 00:00:00'
      var endDate = new Date(str)
      var end = endDate.getTime()

        //时间差
        var leftTime = end-now
        //定义变量 d,h,m,s保存倒计时的时间
        var d,h,m,s
        if (leftTime>=0) {
            d = Math.floor(leftTime/1000/60/60/24)
            h = Math.floor(leftTime/1000/60/60%24)
            m = Math.floor(leftTime/1000/60%60)
            s = Math.floor(leftTime/1000%60)
        }
        //将0-9的数字前面加上0，例1变为01
        d = this.checkTime(d)
        h = this.checkTime(h)
        m = this.checkTime(m)
        s = this.checkTime(s)
        console.log('获取时间',d,h,m,s)
        return  d+'天'+h+'时'+m+'分'+s+'秒'

    },
    checkTime(i){
        return i<10 ? '0' + i : i
    },
    handleSelectionChange(){

    },
    OrderDetailFinish() {
      this.$notification.success({
        message: '提交成功',
        description: '您已成功提交发货单，请耐心等待工作人员审核'
      })
      this.$router.go(0)
    },
    RefundOrderFinish() {
      this.$notification.success({
        message: '申请售后成功',
        description: '您已成功申请售后，请耐心等待工作人员审核'
      })
      this.$router.go(0)
    },
    CancelOrderFinish() {
      this.$notification.success({
        message: '取消订单成功',
        description: '您已成功取消该订单'
      })
      this.$router.push({path: '/user/order'})
    },
    cancelOrder() {

    },
    selectPaymentOk(val) {
      console.log('sdadas',val)
      if(this.OrderInfo.paymentPrice <= 0) return this.$message('订单金额不能小于0！')
      if (this.payType === 0) {
        const postData = {
          orderId: this.OrderInfo.id,
          orderSn: this.OrderInfo.orderNo,
          payType: 'wx'
        }

        unifiedOrder(postData).then((res) => {
          if(res.data != null) {
            this.ModalWechatPay = true
            this.codeUrl = res.data.codeUrl
          }
        })

      }
      if (this.payType === 1) {
        const postData = {
          orderId: this.OrderInfo.id,
          orderSn: this.OrderInfo.orderNo,
          payType: 'ali'
        }

        unifiedOrder(postData).then((res) => {
          window.open( res.data,'_blank')
          this.open = '1'
          this.openModel = true
          localStorage.setItem('open',this.open)
        })
      }
      // 余额支付
      if(this.payType === 2){
        if (this.overallBalance < this.OrderInfo.paymentPrice) {
          Modal.confirm({
            title: '提示',
            centered: true,
            content: '当前余额不足，请尽快充值！或者使用其它支付方式进行支付。',
            onOk: () => {
              return this.$router.push('/user/general/view')
            },
          })
          return
        }
        getUserInfoRz().then((res) => {
          if (res.ok) {
            this.userInfoPhone = res.data.phone
          }
        })
        this.yupayVisible = true
      }
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f1f1f1;
}
::v-deep .el-table tr{
  background-color: #fdf2e7;
}
.active{
  border: 1px solid #ddd;
  padding: 5px 15px;
  border-radius:5px;
  width: 220px;
  margin-bottom: 10px;
  cursor: pointer;
}
.active:hover{
  border: 1px solid #df1f23;
}
.isc::after{
  // position: absolute;
  border-style: solid;
	border-color: green;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotateZ(45deg);
	content: "";
}
.dis{
  display: flex;
  justify-content: space-between;
}
.w{
  width: 80px;
}
.disFixe{
  display: flex;
  flex-flow: row;
  margin: 10px;
}
.OrderDetail{
  background: #ffffff;
}
.remark-box{
    border: 1px solid #ddd;
    font-size: 12px;
    color: #999;
    width: 500px;
    height: 60px;
    resize: none;
    padding: 6px;
    line-height: 18px;
    outline: 0;
}
.dis{
  display: flex;
  // justify-content: space-between;
  align-content: center;
  div{
    margin-left: 10px;

  }
}
.retreat .unify>div {
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  line-height: 38px;
}

.ant-form-item {
  margin-bottom: unset;

  .ant-form-item-label {
    line-height: 30px;
  }

  ::v-deep label {
    font-size: 16px;
    color: $color-orange;
  }

  ::v-deep .ant-form-item-children {
    font-size: 18px;
  }

}

.card {
  max-width: 500px;
}

.orderInfo, .card {
  img {
    width: 150px;
    max-width: 100%;
    max-height: 100%;
  }

  background: #fff;
  box-shadow: 0 7px 23px 0 rgb(90 116 148 / 22%);
  border-radius: 10px;
}

::v-deep .ant-badge-status-dot {
  height: 10px;
  width: 10px;
}

::v-deep .ant-badge-status-text {
  font-size: 22px;
}
</style>
